.forgotPassword {
	font-size: 14px;
	line-height: 1.5;
	color: #666;
	margin: 10px 0 0;
}

.error {
	color: #f44336;
}

.message {
	color: green;
	margin: 10px auto 5px;
	text-align: center;

	.error {
		color: red;
	}
}

div .submit {
	margin-top: 20px;
}
