.title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px !important;
}

div .infoBlock {
    margin-bottom: 6px;

    .value {
        font-weight: 500;
    }
}

.icon {
    margin-left: 10px;
    position: relative;
    top: 5px
}

.token {
    border-radius: 6px;
    line-height: 12px;
    width: 200px;
    font-size: 16px;

    .title {
        margin-right: 10px;
        font-size: 14px;
    }

    .count {
        font-weight: 600;
    }
}

.circular {
    text-align: center;
}

.isOtvetoUser {
    margin-right: 10px;
    position: relative;
    top: 6px;

    &:hover {
        color: rgba(34, 79, 241, 0.69);
        cursor: pointer;
    }
}

.mode {
    text-align: end;
    font-size: 14px;
    font-weight: 600;
    margin: 40px 0 0;
    line-height: 20px;
    color: rgba(10, 49, 188, 0.69);

    &:hover {
        cursor: pointer;
    }
}

.progress {
    position: absolute;
    left: 410px;
}