.dialogContent {
    height: 580px;

    .title {
        font-weight: 600;
        text-align: center;
        font-size: 19px;
        margin-bottom: 10px;
    }
}

.tabs {
    .flexContainer {
        justify-content: center;
    }

    .tab {
        text-transform: none;
        font-size: 16px;
    }
}

.formPayment {
    margin-top: 30px;
}