.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #bdbdbd;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;

    &:hover {
        border-color: #2196f3;
    }

    &.isDragReject {
        border-color: #ff1744;
    }

    &.isDragActive {
        border-color: #2196f3;
    }

    &.isDragAccept {
        border-color: #00e676;
    }
}

.files {
    padding-top: 20px;
    overflow: hidden;

}

.file {
    position: relative;

    &:hover .delete {
        opacity: 1;
    }
}

.img {
    background-size: cover;
    background-position: center center;
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.delete {
    position: absolute !important;
    top: 0;
    right: 0;
    background: rgba(0,0,0,.4) !important;
    color: #fff !important;
    width: 22px;
    height: 22px;
    margin: 4px !important;
    opacity: 0;

    &:hover {
        background: red !important;
    }
}