div .input {
    font-size: 14px;
    background-color: #ffffff;
    transition: .2s ease-in-out;
    min-width: 350px;
    padding-right: 5px;

    &.focusedInput {
        outline-color: #F1ECFD;
        border: #9B9EAC;
    }

    &.errorInput {
        outline-color: #d32f2f;
    }
}

.error {
    position: absolute;
    z-index: 3;
    width: inherit;
    margin-top: 10px;
}

div .buttonRoot {
    background-color: #2264d1;
    color: #ffffff;

    &:hover {
        background-color: rgba(34, 100, 209, 0.77);
    }
}

.title {
    font-weight: 600;
    text-align: center;
    font-size: 18px;
}