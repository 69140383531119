.stat {
    font-weight: 500;
    font-size: 14px;

    .link {
        &:hover {
            cursor: pointer;
        }
    }
}

div.tooltip {
   min-width: 380px !important;
    background-color: #ffffff;
    color: black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 10px;
    font-size: 13px;
}
