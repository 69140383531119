.wrapper {
	position: relative;
}

.buttonProgress {
	color: green;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -10px;
	margin-left: -10px;
}
