.paper {
    background-color: transparent !important;
    overflow: unset !important;

    .content {
        background: var(--colorWhite);
        padding: 20px 20px 10px;
        box-shadow: 0 0 4px rgba(155, 158, 172, 0.28);
        border-radius: 4px;

        .btn {
            text-transform: capitalize;
        }
    }

    .message {
        text-align: center;
        padding-bottom: 20px;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
    }
}