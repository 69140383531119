.container {
    width: 360px;
    max-width: calc(100% - 50px);
    margin: 100px auto;
    transition: .2s ease-in-out;
    padding: 20px;
    text-align: center;
    background-color: rgba(253, 254, 255, 0.71);
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);

    .title {
        font-size: 30px;
        font-weight: bold;
        line-height: 1.2;
        color: #000000;
        margin-bottom: 20px;
    }

    .description {
        font-size: 14px;
        line-height: 1.5;
        color: #666;
        margin-bottom: 10px;
    }

    .error {
        font-size: 12px;
        color: #9B9EAC;
        margin: 10px 0;
    }
}