@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

body {
  font-family: Montserrat, sans-serif !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span, input, div, h1, h2, h3, h6, button, a, td {
  font-family: Montserrat, sans-serif !important;
}

a {
  color: #000000;

  &:hover {
    text-decoration: none;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: inherit;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(197, 197, 197, 0.53);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #e3e3e3;
}
