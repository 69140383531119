div .button {
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;

    @media (--from-laptop) {
        margin-top: 10px;
        width: 200px;
    }

    &:hover {
        color: #23242c;
        background-color: rgba(157, 160, 177, 0.48);
    }
}